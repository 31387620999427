import type { FormError } from '#ui/types'
import { camelToSnake, capitalizeFirst } from './string'

export function processValidationErrors(response: any):
  | {
      message?: string
      errors?: FormError[]
    }
  | false {
  const fieldErrors = (response?.data?.errors ||
    response?.errors ||
    response?.data?.fieldErrors) as Array<{
    field: string
    message: string
  }>
  if (!fieldErrors) return false

  let errorMessage: string | undefined // = response.data?.message
  const outputErrors: FormError[] = []

  if (Array.isArray(fieldErrors)) {
    fieldErrors.forEach((fieldError) => {
      outputErrors.push({
        path: camelToSnake(fieldError.field),
        message: capitalizeFirst(fieldError.message)
      })
    })
  } else {
    Object.entries(fieldErrors).forEach(([path, message]) => {
      if (!message) return
      if (path === 'error') {
        errorMessage = capitalizeFirst(message.toString())
        return
      }
      outputErrors.push({ path: camelToSnake(path), message: capitalizeFirst(message.toString()) })
    })
  }

  return {
    message: errorMessage,
    errors: outputErrors
  }
}
